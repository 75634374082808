<template>
	<div class="visa-more-country-total">
		<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/qqqz_tt%402x.png" class="title-icon-1" />
		<div class="content">
			<div class="product-bar">
				<HomeTitleBar :type="Number('10')" title="全部地区签证" desc="" :tab_array="[]" :hideMore="true">
				</HomeTitleBar>
			</div>
		</div>
		<div :class="index % 2 == 1 ? 'country-bar' : 'country-bar gray'" v-for="(item, index) in list"
			:key="item.wordType">
			<div class="content">
				<div class="word">{{ item.wordType }}</div>
				<div class="country-item-bar">
					<div class="country-item" v-for="child in item.countList" :key="child.areaId"
						@click="clickArea(child.areaId, child.areaName)">
						<img :src="child.areaImg" class="country-icon" />
						<div class="country-name">{{ child.areaName }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getVisaMoreCity
	} from "@/api/index";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		components: {
			HomeTitleBar,
		},
		data() {
			return {
				list: [],
			};
		},
		created() {
			this.getVisaMoreCity();
		},
		methods: {
			clickArea(id, name) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/VisaMoreProduct?areaid=" + id + "&areaname=" + name + "&tab_name=7&org=" + org,
					"_blank"
				);
			},
			getVisaMoreCity() {
				getVisaMoreCity().then((res) => {
					console.log(res);
					this.list = res.unitList;
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.visa-more-country-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;

		.gray {
			background-color: #f9f9f9;
		}

		.country-bar {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 29px;
			align-items: center;

			div.word {
				font-size: 24px;
				line-height: 34px;
				color: #333333;
				opacity: 1;
			}

			div.country-item-bar {
				display: flex;
				flex-wrap: wrap;
				margin-top: 29px;

				div.country-item {
					margin-right: 22px;
					margin-bottom: 29px;
					display: flex;
					flex-direction: column;
					cursor: pointer;

					img.country-icon {
						width: 100px;
						height: 76px;
					}

					div.country-name {
						margin-top: 12px;
						width: 100px;
						text-align: center;
						font-size: 14px;
						font-weight: 400;
						line-height: 14px;
						color: #666666;
						opacity: 1;
					}
				}
			}
		}

		.title-icon-1 {
			width: 100%;
		}

		.content {
			width: 1200px;

			.product-bar {
				display: flex;
				z-index: 1;
				width: 1200px;
				flex-direction: column;

				.product-more-bar {
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
		}
	}
</style>
